export enum routeNames {
    LOGIN_PAGE = "LoginPage",
    DEFAULT = "Default",
    INVOICE = "Invoice",
    FORM = "Form",
    SUPPLEMENTARY_FORM = "SupplementaryForm",
    FORM_PAYMENT = "FormPayment",
    PAYMENT_METHOD = "PaymentMethod",
    PAYMENT_METHOD_SELECTION = "PaymentMethodSelection",
    TRANSFER_DETAILS = "TransferDetails",
    DISABLED_PAYMENT_METHODS = "DisabledPaymentMethods",
    NOT_FOUND = "404",
    OPAYO_IFRAME = "OpayoIframe",
    PAYMENT_STATUS = "PaymentStatus",
    ACCESS_DENIED = "AccessDenied",
    TERMS_AND_CONDITIONS = "TermsAndConditions",
    PRIVACY_POLICY = "PrivacyPolicy",
    OLD_PAYMENT_REQUEST_LINK = "OldPaymentRequestLink",
    REEDS_LANDING_PAGE = "ReedsLandingPage",
    E_MANDATES_LANDING_PAGE = "EMandatesLandingPage",
    E_MANDATES_FORM = "EMandatesForm",
    E_MANDATES_CONFIRMATION = "EMandatesConfirmation",
    AUTH_REDIRECT = "AuthRedirect",
    AUTH_SIGN_OUT = "AuthSignOut",
}
