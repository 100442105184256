<template>
    <v-app class="overflow-hidden">
        <v-main>
            <v-row class="app-bar">
                <app-bar />
            </v-row>
            <v-container fluid>
                <div class="vertical-spacer"></div>
                <v-row justify="center" class="align-center">
                    <v-col
                        cols="12"
                        xs="12"
                        sm="10"
                        md="6"
                        class="main-content"
                    >
                        <div v-if="displayHeader" class="main-content-header">
                            <div class="organisation-logo-container">
                                <img
                                    class="organisation-logo"
                                    :src="`https://imagedelivery.net/cdn-cgi/image/width=150/C3ccYXB38iKjIvgGGu5HTg/${cloudflareLogoShortcode}/public`"
                                    :alt="`${String(
                                        cloudflareLogoShortcode,
                                    )} logo`"
                                    data-cy="organisation-logo"
                                    :onerror="redirectTo404"
                                />
                            </div>
                            <h2
                                class="center-align-text capitalized-text dark-text no-print"
                            >
                                {{ getPaymentTitle }}
                            </h2>
                        </div>
                        <router-view></router-view>
                        <alert
                            v-model="alertConfiguration.visible"
                            :alert-message="alertConfiguration.message"
                            :alert-color="alertConfiguration.color"
                            :internationalised-message="
                                alertConfiguration.internationalisedMessage
                            "
                        ></alert>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <v-footer v-if="displayFooter" class="footer no-print">
            <v-row justify="center" class="align-center footer-contents">
                <v-col cols="12" xs="12" sm="12" md="5" class="no-print">
                    <a
                        @click.prevent="
                            handleLinksRedirect('TermsAndConditions')
                        "
                        >Terms And Conditions</a
                    >
                    <a @click.prevent="handleLinksRedirect('PrivacyPolicy')"
                        >Privacy Policy</a
                    >
                    <p>&copy;Esenda {{ new Date().getFullYear() }}</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="5" class="no-print">
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="2" class="no-print">
                </v-col>
            </v-row>
        </v-footer>
    </v-app>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, watch } from "vue";
import { usePiniaStore } from "@/plugins/store";
import { useRouter, useRoute } from "vue-router";
import Intercom from "@intercom/messenger-js-sdk";

import alert from "@/components/base/alert.vue";
import appBar from "@/templates/appBar.vue";

import { useAuthenticator } from "@aws-amplify/ui-vue";
import { setSentryUser } from "@/helpers/sentry";
import { alertConfiguration, showAlert } from "@/helpers/errorHandling";
import { extractShortcodeFromUrl } from "@/helpers/stringProcessors";
import { getOrganisationData } from "@/apis/esendaV2/organisations";
import { handleLogout } from "./helpers/session";
import { routeNames } from "./constants/routeNames";
import {
    getCurrentAuthenticatedUser,
    getEmailFromCurrentSession,
} from "@/helpers/auth";

const pStore = usePiniaStore();
const router = useRouter();
const route = useRoute();
const auth = useAuthenticator();
const isAuthenticated = ref(auth.authStatus === "authenticated");

const shortcode = computed(() => route.params.shortcode);
const formName = computed(() => route.params.form);
const accountAlias = computed(() =>
    route.params.accountAlias ? String(route.params.accountAlias) : null,
);

/** Shortcodes not associated to an organisation */
const shortcodeExceptions = ref([
    "",
    "unauthorised",
    "opayo-3ds-iframe",
    "terms-and-conditions",
    "privacy-policy",
    "reeds",
]);
/** Routes where we don't want to display the organisation logo */
const getImageRouteExceptions = ref([
    routeNames.DEFAULT,
    routeNames.NOT_FOUND,
    routeNames.OPAYO_IFRAME,
    routeNames.ACCESS_DENIED,
    routeNames.TERMS_AND_CONDITIONS,
    routeNames.PRIVACY_POLICY,
    routeNames.REEDS_LANDING_PAGE,
]);

const getShortcodeForCloudFlare = () => {
    /**
     * Extracts the shortcode from window.location.href.
     * Handles redirection case, where it updates the auth
     * shortcode with the stored shortcode
     */
    const shortcode = extractShortcodeFromUrl(window.location.href);
    if (shortcode === "auth") {
        return String(pStore.getShortcode);
    } else {
        return shortcode;
    }
};

const cloudflareLogoShortcode = ref(getShortcodeForCloudFlare());

const redirectTo404 = () => {
    /**
     * Redirects the user to the 404 page. If the shortcode is in
     * the list of exceptions we abort the function. Otherwise,
     * we redirect to 404.
     */
    if (shortcodeExceptions.value.includes(cloudflareLogoShortcode.value)) {
        return;
    }

    router.push({ name: "404" });
};

const handleLinksRedirect = (viewName: string) => {
    /**
     * Opens a new tab to show the view corresponding on the link he clicked.
     * @param {string} viewName
     */
    const routeData = router.resolve({ name: viewName });
    window.open(routeData.href, "_blank");
};

const getPaymentTitle = computed(() => {
    /**
     * Returns eMandates as title if the url includes e-mandates.
     * Returns the payment title to be displayed below the organisation logo.
     * If form name is "partner_link" it returns the description
     * property from the supplementaryData object as the title, otherwise it
     * returns the form name.
     */
    if (
        pStore.getFormName === "partner_link" &&
        pStore.getSupplementaryData &&
        pStore.getSupplementaryData.description
    ) {
        return pStore.getSupplementaryData.description;
    } else if (window.location.href.includes("e-mandates")) {
        return "Setup Electronic Mandates";
    } else {
        return `${pStore.getFormName} payment `;
    }
});

const displayHeader = computed(() => {
    /**
     * Hides the organisation logo header if the current route is in the
     * list of route exceptions.
     */
    const isAuthRoute = window.location.href.includes("auth");
    const routeName = route.name as routeNames;
    const isExceptionRoute = getImageRouteExceptions.value.includes(routeName);
    return !isExceptionRoute && !isAuthRoute;
});

const displayFooter = computed(() => {
    return route.name !== routeNames.OPAYO_IFRAME;
});

const endSession = () => {
    /**
     * Clears the session data and redirects logs
     * out the user
     * @return {[void]}
     */
    const shortcodeParam =
        shortcode.value === undefined || shortcode.value === null
            ? pStore.getShortcode
            : shortcode.value;
    const formNameParam =
        formName.value === undefined || formName.value === null
            ? pStore.getFormName
            : formName.value;

    pStore.clearSessionData();

    if (isAuthenticated.value) {
        handleLogout();
    } else {
        let redirectTo = "Invoice";
        const pathParams = { shortcode: shortcodeParam };

        if (formNameParam !== "invoice") {
            redirectTo = "Form";
            pathParams["form"] = formNameParam;
        }

        if (
            route.name !== redirectTo &&
            !getImageRouteExceptions.value.includes(route.name as routeNames)
        ) {
            showAlert("alerts.sessionTimeout", true);
            router.push({ name: redirectTo, params: pathParams });
        }
    }
};

watch(
    auth,
    async () => {
        isAuthenticated.value = auth.authStatus === "authenticated";
        pStore.setIsAuthenticated(isAuthenticated.value);

        if (isAuthenticated.value) {
            // get user details from Cognito
            const email = await getEmailFromCurrentSession();
            pStore.setEmailAddress(email as string);
            const currentUser = await getCurrentAuthenticatedUser();

            // Enable intercom
            Intercom({
                app_id: import.meta.env.VITE_INTERCOM_APP_ID,
                user_id: currentUser?.userId as string,
                email: pStore.getEmailAddress as string,
                created_at: Date.now(),
            });
        }
    },
    { immediate: true },
);

const detectIdleSession = () => {
    /**
     * Detects period of user inactivity and resets session
     * after 2h of inactivity
     * @return {[void]}
     * */

    let time;
    window.onload = resetTimer;

    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;
    document.onmousedown = resetTimer; // catches touchscreen presses as well
    document.ontouchstart = resetTimer; // catches touchscreen swipes as well
    document.ontouchmove = resetTimer; // required by some devices
    document.onclick = resetTimer; // catches touchpad clicks as well

    function resetTimer() {
        clearTimeout(time);
        // TODO ensure 2h is the right time
        time = setTimeout(endSession, 7200000);
    }
};

const getOrganisation = async () => {
    /**
     * Calls the organisation api. If the shortcode from the route is in the
     * list of exceptions we abort the function. If the api returns an organisation
     * we save it in the session storage. If it returns 404, we redirect the user
     * to the 404 page.
     * @returns {[void]}
     */
    const currentShortcode = String(shortcode.value);
    if (shortcodeExceptions.value.includes(currentShortcode)) {
        return;
    }

    await getOrganisationData(currentShortcode, String(pStore.getFormName));
};

pStore.setDefaultCountryCode();
pStore.setDefaultLanguage();

onMounted(async () => {
    await router.isReady();

    if (pStore.getSessionId === null) {
        pStore.setSessionId();
    }

    if (!window.location.href.includes("auth")) {
        pStore.setAccountAlias(accountAlias.value);
        pStore.setFormName(String(formName.value));
    }

    setSentryUser(String(pStore.getEmailAddress), String(pStore.getSessionId));

    if (shortcode.value !== undefined) {
        getOrganisation();
    }

    window.onload = function () {
        detectIdleSession();
    };
});
</script>
