<template>
    <v-col cols="2">
        <img
            alt="Esenda logo"
            :src="esendaLogoPng"
            class="esenda-logo my-0 py-0 ml-4"
        />
    </v-col>
    <v-spacer></v-spacer>
    <language-selector
        :class="isAuthenticated ? '' : 'mr-4'"
    ></language-selector>
    <logged-in-user
        v-if="isAuthenticated"
        :user-picture="''"
        :user-name="username"
    ></logged-in-user>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { usePiniaStore } from "@/plugins/store";

import loggedInUser from "@/components/group/loggedInUser.vue";
import languageSelector from "@/components/base/languageSelector.vue";

import esendaLogoPng from "@/assets/logos/esenda-black-logo.png";
import { getCurrentAuthenticatedUser } from "@/helpers/auth";

const pStore = usePiniaStore();

const isAuthenticated = computed(() => pStore.isAuthenticated);
const getUser = async () => {
    if (pStore.isAuthenticated) {
        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser && currentUser.username)
            pStore.setEmailAddress(currentUser.username);
    }
};
getUser();

const username = computed(() => pStore.getEmailAddress ?? "");
</script>
