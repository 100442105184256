import posthog, { EventName } from "posthog-js";
import { usePiniaStore } from "@/plugins/store";
import { configSentryError } from "@/helpers/sentry";

const usePosthog = () => {
    /**
     * Initializes and provides access to the PostHog instance.
     * PostHog is used for capturing analytics events.
     *
     * @returns {Object} An object containing the initialized PostHog instance.
     */
    const pStore = usePiniaStore();

    posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
        api_host: import.meta.env.VITE_POSTHOG_API_HOST,
        capture_pageview: false,
        /** Disabled session recording */
        disable_session_recording: true,
        /** Hide inputs & text content in session replays */
        session_recording: {
            maskAllInputs: true,
            maskTextSelector: "*",
        },
        /** Disabled autocapture recording */
        autocapture: false,
    });

    // Register default properties
    posthog.register({
        application: "payments-frontend",
        version: "1",
        environment: import.meta.env.VITE_ENVIRONMENT,
        email: pStore.getEmailAddress,
        shortcode: pStore.getShortcode,
        session_id: pStore.getSessionId,
    });

    return {
        posthog,
    };
};

export const captureEvent = (eventName: EventName, properties = {}) => {
    /**
     * Captures an analytics event using PostHog with additional properties.
     *
     * @param {EventName} eventName - The name of the event, following the naming convention:
        - Use lowercase letters only.
        - Format: category:object_action (e.g., bacs:file_upload).
        - Use present-tense verbs (e.g., upload, create).
        - Allowed verbs include: click, submit, create, view, add, update, delete, start, end, etc.
     * @param {Object} [properties={}] - Optional properties to include in the event payload.
    */
    const posthogIntegration = usePosthog();

    if (import.meta.env.VITE_ENVIRONMENT === "local") {
        console.info(
            "PostHog is disabled in the local environment for this session.",
        );
        return;
    }

    try {
        if (posthogIntegration?.posthog) {
            posthogIntegration.posthog.capture(eventName, properties);
        } else {
            configSentryError(
                "posthog",
                "captureEvent",
                `Failed to capture event: ${eventName}. PostHog instance is not available.`,
            );
        }
    } catch (error) {
        configSentryError(
            "posthog",
            "captureEvent",
            `Failed to capture event: ${eventName}. Error: ${error}`,
        );
    }
};

export default usePosthog;
