/**
 * The method below uses the auth integration to return an access token
 * which will be used when making calls to the API.
 */
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { usePiniaStore } from "@/plugins/store";
import { redirectToRoute } from "@/plugins/router";
import { routeNames } from "@/constants/routeNames";
import { showAlert } from "@/helpers/errorHandling";
import { shutdown } from "@intercom/messenger-js-sdk";

const postLogoutRedirect = () => {
    /**
     * Determine which route to send user to after logout
     * based on the origin
     */
    const pStore = usePiniaStore();

    if (!pStore.getShortcode) {
        redirectToRoute(routeNames.DEFAULT, {});
    }

    if (pStore.getOrigin === routeNames.E_MANDATES_LANDING_PAGE) {
        redirectToRoute(routeNames.E_MANDATES_LANDING_PAGE, {
            shortcode: pStore.getShortcode,
            accountAlias: pStore.getAccountAlias,
        });
    } else {
        const formName = pStore.getFormName ?? "invoice";
        redirectToRoute(routeNames.FORM, {
            shortcode: pStore.getShortcode,
            form: formName,
        });
    }
};

export const getToken = async () => {
    const pStore = usePiniaStore();

    try {
        const session = await fetchAuthSession();
        const token = session.tokens?.accessToken;
        if (!token) {
            //** Sign out user if we cannot get a token */
            pStore.setIsAuthenticated(false);
            pStore.setUser(null);
            shutdown(); // closes the intercom popup on sign out

            await signOut({ global: true });

            showAlert("alerts.expiredSession", true);
            postLogoutRedirect();
            return null;
        }
        return token;
    } catch (error) {
        /** Sign out user if a token cannot be retrieved */
        pStore.setIsAuthenticated(false);
        pStore.setUser(null);
        shutdown(); // closes the intercom popup on sign out

        await signOut({ global: true });
        showAlert("alerts.expiredSession", true);
        postLogoutRedirect();
        return null;
    }
};
