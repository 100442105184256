import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { configSentryError } from "./sentry";
import { usePiniaStore } from "@/plugins/store";

export const getCurrentAuthenticatedUser = async () => {
    /**
     * Uses the auth integration to return user information about
     * the current authenticated user.
     */
    try {
        const user = await getCurrentUser();
        const pStore = usePiniaStore();
        pStore.setUser(user);
        return user;
    } catch (error) {
        configSentryError(
            "auth.ts",
            "getCurrentAuthenticatedUser",
            `Error while trying get the current user info: ${error}`,
        );
    }
};

export const getEmailFromCurrentSession = async () => {
    /**
     * Uses the auth integration to return user's email address
     * from the idToken of the current session
     */
    try {
        const session = await fetchAuthSession();
        const email = String(session.tokens?.idToken?.payload.email);
        return email;
    } catch (error) {
        configSentryError(
            "auth.ts",
            "getEmailFromCurrentSession",
            `Error while trying get the username from the current session: ${error}`,
        );
    }
};
