import { createApp } from "vue";
import App from "./App.vue";
import * as Sentry from "@sentry/vue";
import "./assets/style/global.css";
import router from "./plugins/router";
import i18n from "./plugins/vi18n";
import vuetify from "./plugins/vuetify";
import LoadScript from "vue-plugin-load-script";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { Amplify } from "aws-amplify";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "aws-amplify/utils";
import { showAlert } from "./helpers/errorHandling";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: `${import.meta.env.VITE_APP_NAME}@${
        import.meta.env.VITE_APP_VERSION
    }`,
    tracePropagationTargets: [import.meta.env.VITE_API_URL, /^\//],
    integrations: [
        Sentry.browserTracingIntegration({
            router,
            routeLabel: "name",
        }),
        Sentry.replayIntegration({
            // masks PI from inputs/text on session replays
            // can be altered to allow only certain inputs to be hidden
            // more on https://docs.sentry.io/platforms/javascript/guides/vue/session-replay/privacy/
            maskAllText: true,
            // items in the dom with the following class will show up in the session replay
            unmask: [".sentry-unmask, [data-sentry-unmask]"],
            // Capture request information on in the network tab
            networkDetailAllowUrls: [import.meta.env.VITE_OPAYO_3DS_URL],
            networkDetailDenyUrls: [
                `${import.meta.env.VITE_OPAYO_3DS_URL}/api/v1/card-identifiers`,
            ],
            networkCaptureBodies: true,
        }),
        Sentry.vueIntegration({
            tracingOptions: {
                trackComponents: false, // this needs to be off until this issue is fixed https://github.com/getsentry/sentry-javascript/issues/8143
            },
        }),
    ],
    debug: import.meta.env.VITE_ENVIRONMENT !== "production",
    tracesSampler: ({ name }) => {
        // Do not sample health checks ever
        if (name.includes("OpayoIframe")) {
            // Drop this completelty, by setting its sample rate to 0%
            return 1;
        }

        // Else, use default sample rate
        return 0;
    },
    attachProps: true,
    attachStacktrace: true,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.VITE_ENVIRONMENT,
    enabled: import.meta.env.VITE_ENVIRONMENT !== "local",
    /** List taken from Sentry's gist https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry */
    ignoreErrors: [
        "Non-Error promise rejection captured",
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        // Network errors such as going offline or being blocked by a proxy
        "Failed to fetch",
        /**
         * error triggered by IP addresses from Microsoft Corporation. This seems to be the issue:
         * "antifingerprint not defined yet. will try and handle event after its ready."
         * No solution/cause yet, but threads recommend to filter out the error:
         * https://github.com/getsentry/sentry-javascript/issues/9143
         * 688 events triggered in Sentry for 688 distinct users. The ip addresses point to Microsoft Corporation
         * */
        "TypeError: Illegal invocation",
        "TypeError: cancelled", // See https://stackoverflow.com/a/70452078
        // Sentry error triggered when RUM cannot be collected
        "TypeError ?(rum)",
        "(rum)",
        /^performance\.getEntriesByType is not a function\. \(In 'performance\.getEntriesByType\("navigation"\)', 'performance\.getEntriesByType' is undefined\)$/,
        /^s\.getEntriesByType is not a function\. \(In 's\.getEntriesByType\(r\)', 's\.getEntriesByType' is undefined\)$/,
    ],
    denyUrls: [
        // Safari webkit
        /.*webkit-masked-url.*/,
        /^webkit-masked-url:\/\/hidden\/$/,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Other plugins
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        // Firefox extensions
        /^resource:\/\//i,
    ],
    // maximum number of characters a message can have
    // before it will be truncated
    maxValueLength: 1000,
});

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: import.meta.env.VITE_COGNITO_PAYMENT_CLIENT_ID,
            userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
            loginWith: {
                email: true,
                oauth: {
                    domain: import.meta.env.VITE_COGNITO_DOMAIN,
                    scopes: ["email", "openid"],
                    redirectSignIn: [`${window.origin}/auth/redirect`],
                    redirectSignOut: [`${window.origin}/auth/sign-out`],
                    responseType: "token",
                    providers: ["Google"],
                },
            },
            userAttributes: {
                email: {
                    required: true,
                },
            },
        },
    },
});

/** Store the token&user info in cookie storage*/
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

app.use(pinia).use(router).use(vuetify).use(i18n).use(LoadScript).mount("#app");

/** Ensure errors import module chunks failures are handled */
window.addEventListener("vite:preloadError", (event) => {
    // Added breadcrumb in Sentry so we can connect errors to this event
    Sentry.addBreadcrumb({
        category: "vite:preloadError",
        message: "Import module error triggered " + event,
        level: "info",
    });
    showAlert("alerts.newAppVersion", true);
});
