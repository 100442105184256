import { createI18n } from "vue-i18n";
import en from "@/plugins/vi18n/locales/en-GB.json";
import fr from "@/plugins/vi18n/locales/fr-FR.json";

// List of supported languages
const supportedLocales: Record<string, any> = {
    "en-GB": en,
    "fr-FR": fr,
};

// Return the language from the store if it exists
const store = JSON.parse(sessionStorage.getItem("store") || "{}");
const userLocale = store?.language || navigator.language;
// Check if the local language is supported. If not, use "en-GB" as a fallback.
const locale = Object.keys(supportedLocales).includes(userLocale)
    ? userLocale
    : "en-GB";

const i18n = createI18n({
    locale,
    fallbackLocale: "en-GB",
    allowComposition: true,
    messages: supportedLocales,
});

export const translationExists = (key: string): boolean => {
    /**
     * Checks if the key exist in the list of internationalised messages.
     * It checks in the "en-GB" locale for now as we do not have any other
     * translation languages enabled. Once we add more language support, we
     * can change the "en-GB" value to the user's locale
     * @param {string} key
     * @returns {boolean}
     */
    return i18n.global.te(key, locale);
};

export default i18n;
