<template>
    <div class="no-print">
        <v-select
            v-model="locale"
            @update:modelValue="onSelectValueChange"
            :items="localeOptions"
            item-title="title"
            item-value="value"
            class="language-selector-height"
            density="compact"
            variant="outlined"
            color="secondary"
            base-color="slate-grey"
            data-cy="language-selector"
            hide-details
            item-color="black"
            :width="120"
        ></v-select>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { capitaliseFirstLetterOfFirstWord } from "@/helpers/stringProcessors";
import { captureEvent } from "@/plugins/posthog";
import { usePiniaStore } from "@/plugins/store";

const pStore = usePiniaStore();
const { locale, availableLocales } = useI18n();

captureEvent("settings:set_user_default_locale", {
    userLocale: locale.value,
});

const localeOptions = computed(() =>
    availableLocales.map((loc) => {
        // Use just the language code (first part before any region, e.g., "en", "fr")
        const languageCode = loc.split("-")[0];

        // Get the language name using Intl.DisplayNames with the language code
        const displayName = new Intl.DisplayNames([languageCode], {
            type: "language",
        }).of(languageCode);

        return {
            value: loc,
            title: displayName
                ? capitaliseFirstLetterOfFirstWord(displayName)
                : loc, // Fallback to the locale code if no name found
        };
    }),
);

const onSelectValueChange = (newValue: string) => {
    /** Update the selected language in the store and send event for analytics*/
    pStore.updateLanguage(newValue);
    captureEvent("settings:update_language", {
        userLocale: locale.value,
        selectedLanguage: newValue,
    });
};
</script>
